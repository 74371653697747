<template>
  <div>
    <span class="pointer"
      v-b-popover.hover.html="getUserAgentHtml(params.data.UserAgent)">
      {{ params.data[params.column.colId] }}
    </span>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
    };
  },
  methods: {
    getUserAgentHtml(obj) {
      let html = '';
      const userAgent = JSON.parse(obj);
      Object.entries(userAgent).forEach((value) => {
        html = `${html}<strong>${value[0]}</strong>: ${value[1]}<br>`;
      });
      return html;
    },
  },
});
</script>
